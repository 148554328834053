html {
  min-height: 93%;
}

body {
  background-color: #fbfbfb;
}

strong {
  font-weight: 900;
}

@media (min-width: 420px) {
  .signin, .errors {
    /*max-width: 400px;*/
    margin-top: 100px;
  }
}
@media print {
  .fs-footer, .fs-header, .border,
  .terms-link, #header, #footer { display: none !important;}
}

@media (max-width: 780px) and (min-width: 330px) {
  .fs-footer   {
    text-align: center !important;
    padding: 8px !important;
  }}

@media screen and (min-device-width : 260px) and (max-device-width : 480px) {
  .fs-footer   {
    text-align: center !important;
    padding: 4px !important;
    font-size: 0.67rem !important;
    /*height: auto !important;*/
 }

  .signin {
    /*height: 400px;*/
    padding-top: 60px !important;
    padding-bottom: 20px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }}

.signin {
  /*height: 400px;*/
  padding-top: 60px;
  padding-bottom: 20px;
  padding-left: 105px;
  padding-right: 105px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 75px;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.08);
}
.signin--sm {
  max-width: 600px;
}
.signin--md {
  max-width: 720px;
}

.alert .form-group {
  padding-top: 5px;
}

.form-signin {
  text-align: center;
}

.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"],
.form-signin input[type="username"],
.form-signin input[type="otp"],
.form-signin input[type="backupcode"],
.form-signin input[type="phone"]{
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-radius: 10px;
  max-width: 375px;
  text-align: center;
  font-family: Sohne;
}

.pinc-alert-box-button-middle {
  background-color: #FFB819 !important;
  border-color: #FFB819 !important;
}

input[type="submit"].btn-block {
  width: 120px;
}

.error-code {
  text-align: right;
  margin-bottom: 0;
  /* hack to make this work */
  padding-top: 4px;
  font-size: 0.9rem;
}
.login-error {
  color: red;
}

.help-block {
  width: 400px;
  margin-left: auto;
  margin-right:auto;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 10px;
}

.zeroBottom {
  margin-bottom: 0px;
}
.tinyBottom {
  margin-bottom: .7rem;
}
.login-error-message {
  margin-bottom: 0px;
  font-size: large;
  /* height: 60px; */
}
.login-error-message .nav-container {
  padding-top: 0px;
}
.nav-container {
  padding-left: 0.9rem;
  padding-top: 2rem;
  width: 155px;
  display: inline-block;
}

.nav-container-next {
  padding-left: 0.9rem;
  padding-top: 0.2rem;
  width: 155px;
  display: inline-block;
}
.nav-container-accept{
  padding-top: 2rem;
  width: 300px;
  display: inline-block;
  padding-left: 150px;
}

.alert-container {
  margin-bottom: 0px;
}

.alert + .form-signin {
  padding: 0px;
}

.extraTopMargin {
  margin-top: .6rem;
}

.extraBottomMargin {
  margin-bottom: .6rem;
}

.errorBaseContent {
  font-size: 0.8rem;
}

.help-link {
  text-align: center;
}

.terms-container {
  height: 250px;
  margin: 10px;
}
.terms-link {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: right;
}

.terms-link-print {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: right;
}

.terms-regs {
  font-size: small;
}

.terms-body {
  padding-top: 20px;
}

.reference-codes {
  margin-top: 2rem;
  font-style: italic;
  margin-bottom: 0px;
}

.invalid-feedback {
  font-size: 85%
}

.terms__title {
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}
.terms__subheading {
  text-decoration: underline;
  font-weight: bold;
}
.terms__list {
  list-style-type: lower-alpha;
}
/*.terms__list > ol {*/
/*  list-style-type: lower-roman;*/
/*}*/
.terms__notice {
  font-style: italic;
  font-size: 90%;
}

.terms__header {
  text-align: center;
  text-transform: uppercase;
}

sup {
  font-size: small;
}

.lk_header {
  padding: 10px 0;
  height: 55px;
}

.lk_app_title {
  margin-top: 6px;
  font-family: Sohne;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  text-transform: none;
}

.lk_app_title::after {
  content: "\00ae";
  position: relative;
  top: -9px;
  left: 3px;
  font-size: 8px;
  font-family: Sohne;
}

h2, h3, h4 {
  font-family: Sohne;
  color: #000000;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: -0.02em;
}

@font-face {
  font-family: Sohne;
  src: url('./assets/fonts/soehne-buch.woff2') format('woff2');
  font-style: normal;
  font-weight: 300;
  font-display: block;
}

@font-face {
  font-family: Sohne;
  src: url('./assets/fonts/soehne-kraftig.woff2') format('woff2');
  font-display: block;
  font-weight: bold;
}

@font-face {
  font-family: Sohne;
  src: url('./assets/fonts/soehne-dreiviertelfett.woff2') format('woff2');
  font-display: block;
  font-weight: 900;
}

@font-face {
  font-family: GreycliffCF-Regular;
  src: url('./assets/fonts/GreycliffCF-Regular.woff2') format('woff2');
  font-style: normal;
  font-display: block;
}

a:not(.fs-premierconnect-logo):hover {
  text-decoration: none;
  color: #DA437E;
  border-bottom: 1px dashed #DA437E !important;
}

a {
  color: #DA437E;
  text-decoration: none;
  background-color: transparent;
}
.lk_app_title_home {
  margin-top: 6px;
  font-family: Sohne;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  text-transform: none;
}

.fs-header {
  padding: .600rem .600rem .600rem 25px;
  border-bottom: 4px solid #0091b3;
  visibility: hidden;
}

.fs-branding {
  display: flex;
}

.fs-footer {
  text-align: left;
  height: 54px;
  font-weight: normal;
  padding: 19px 10px 10px;
  font-size: 12px;
  background: none;
  color: #757575;
  text-align: center;
  font-family: Sohne;
}

.fs-footer a {
  text-decoration: none;
  color: #DA437E;
}

.lk_footer {
  padding-left: 20px;
}

.footer-info {
margin: 0 5px;
position: relative;
display: inline-block;
}

.footer-info:first-child {
border: 0;
}

.footer-info a:hover {
    color: #DA437E;
    text-decoration: none;
    border-bottom:1px dashed #DA437E;
}

.footer-info p {
  text-align: center !important;
}

.fs-content .container.fs-app-container {
  padding: 40px;
  font-family: Sohne;
  border-radius: 20px;
  max-width: 700px;
  text-align: center;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.08);
  margin-top: 100px;
}

.hidden-field {
  display: none
}

.signinLink {
  cursor: pointer;
}

.browser-warning  {
  height: 290px;
}

.twofactor-text {
  font-size: 16px;
  font-weight: normal;
  font-family: Sohne;
}
.twofactor-text-hint {
  font-size: 10px;
  font-weight: normal;
  font-family: Sohne;
  color: grey;
}

.twofactor-text-links-back {
  font-size: 12px;
  font-weight: normal;
  font-family: Sohne;
}

.twofactor-text-links,
h4.resend-codes-text,
h4.resetpassword-codes-text,
h4.general-error-component-text{
  font-size: 16px;
  font-weight: normal;
  font-family: Sohne;
}

input#gotit-button {
  display: inline;
}

.another-method {
  font-size: 16px;
  padding-bottom: 10px;
}

.another-method-radio {
  margin-left: auto;
  margin-right:5px;
}

.display-mfa-error {
  font-size: 85%;
  width:100%;
  margin-top:.25rem;
  color:#e51b0d;
}

.lk-password-policy {
  background-color: #FFFFCC;
  position: relative;
  height: auto;
  font-family: Sohne;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 20px;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.08);
}

.password-policy {
  max-width: 600px;
  margin-top: -45px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
}

.font-family-sohne {
  font-family: Sohne;
}

.form-signin input[type="checkbox"]:disabled
{
  background: rgba(204, 204, 204, 0.34);
  appearance: none;
  border-color: black;
}

.pincai-btn-primary {
  /*background: #DA437E;*/
  border-radius: 40px;
  border: 2px solid #DA437E;
  color: #DA437E;
  text-decoration: none;
  height: 40px;
  font-weight: 600;
  letter-spacing: 0.1rem;
  font-family: GreycliffCF-Regular !important;
  font-size: 16px;
}

.pincai-btn-primary-print {
  border-radius: 40px;
  border: 2px solid #DA437E;
  color: #DA437E;
  text-decoration: none;
  height: 40px;
  width: 100px;
  background-color: transparent;
}

.pincai-btn-primary:hover,
.pincai-btn-primary-print:hover {
  border: 1px solid #DA437E;
  background: #DA437E;
  color: #ffffff;
}

.badge-primary{
  background-color:#ffffff;
  color: #DA437E;
  font-family: Sohne;
  font-size: 18px;
  line-height: 14px;
  letter-spacing: -0.02em;
  font-weight: 100;
  padding-bottom: 20px;
}

.pinc-ai-footer-logo {
  justify-content: center;
  padding-bottom: 15px;
}

.fs-premierconnect-logo {
  margin-left: 40px;
}

label {
  margin-bottom: 1.5rem;
}

p {
  font-size: 18px;
  line-height: 24px;
  font-family: Sohne;
}

.pinc-ai-wordmark-align {
  max-width:160px;
  padding-bottom: 40px;
}

.password-div {
  border: 1px solid #bdbdbd;
  border-radius: .125rem;
  color: #616161;
}

.password-input {
  border: none;
}

.password-button:focus {
  outline: none;
}

.password-hide-display-button {
  max-width:40px;
  max-height:40px;
  border: none;
  background-color: transparent;
  background-repeat: no-repeat;
  overflow: hidden;
  outline: none;
  float: right;
  padding-top: 2px;
  padding-bottom: 2px;
}

.pinc-dash {
  border-top: 1px dashed #a1a1a1;
}

.title-block {
  display: block;
  margin-bottom: 0;
}

/*.pinc-ai-terms {*/
/*  padding-bottom: 30px;*/
/*}*/

.fs-bottom {
  margin-bottom: 50px;
}

.list-left-align {
  text-align: left;
}

.list-left-align label {
  margin-bottom: 0.5rem;
}

.alert-success {
  background-color: #eef7ef;
  border-color: #d0e9c6;
  color: #3c763d
}

.pincai-btn-primary:disabled,
.pincai-btn-primary:disabled:hover {
  color: #AAB6BC;
  background-color: #DDE3E4;
  border: none;
}

.label-styling {
  font-size: 0.75rem;
  color: #A0A0A0;
  margin-bottom: 0.5rem !important;
  padding-left: 15px;
}

.container-styling {
  padding: 2.25rem !important;
  font-family: Sohne;
  text-align: left;
}

h1 {
  font-family: Sohne;
  line-height: 1.1;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 300;
}

.passwordRequirementsList {
  padding-left: 1rem;
}

.pwdRevealButton {
  margin: 0 !important;
  background: #ffffff !important;
  border-radius: 20px;
  line-height: 1.5;
  text-transform: none;
  border: 0;
  border-radius: .125rem;
  font-size: 1.23rem !important;

}

.form-chngPwd.nav-container {
  padding-left: 39%;
  padding-top: 2rem;
}

.pwdRevealButton .fa {
  color: #004572;
}